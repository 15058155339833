import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  purchase_id_active(){
    let element = event.target
    let purchase_id = document.getElementById("return_transaction_detail_purchase_order_id")

    if (element.value == "manual") {
      purchase_id.removeAttribute("disabled", "");
      purchase_id.setAttribute("enabled", "");
    }else{
      purchase_id.removeAttribute("enabled", "");
      purchase_id.setAttribute("disabled", "");
    }
    
  }
}
